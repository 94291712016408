import { GoogleOAuthProvider } from '@react-oauth/google';
import ErrorPage from 'pages/ErrorPage';
import { ErrorBoundary } from 'react-error-boundary';
import {
	Navigate,
	Route,
	RouterProvider,
	Routes,
	createBrowserRouter,
	createRoutesFromElements,
} from 'react-router-dom';
import { renderRoutes } from 'routers/RenderRoutes/RenderRoutes';
import { BreadcrumbsProvider } from 'shared/context/breadcrumbs';
import { ConfluenceOauthProvider } from 'shared/context/confluenceOauth';
import { InitializationProvider } from 'shared/context/initialization';
import { OnboardingProvider } from 'shared/context/onboardingContext/onboardingContext';
import { AuthProvider } from 'shared/guards/auth.guard';
import useGetMicrosoftAuthCode from 'shared/hooks/useGetMicrosoftAuthCode';
import MessageDisplayer from 'shared/ui/MessageDisplayer/MessageDisplayer';
import ScrollToTop from 'shared/ui/ScrollToTop';

import Layout from './layouts/Layout';
import { RoutersEnum, RoutersFullpathEnum, useGetPublicRoutes, useGetRoutes } from './routers';
import './styles/main.scss';

const Root = (): JSX.Element => {
	const { routes } = useGetRoutes();

	useGetMicrosoftAuthCode();

	return (
		<ErrorBoundary FallbackComponent={ErrorPage}>
			<Routes>
				{renderRoutes(routes)}
				<Route path="*" element={<Navigate to={RoutersEnum.login} />} />
			</Routes>
		</ErrorBoundary>
	);
};

const PublicRoot = (): JSX.Element => {
	const { publicRoutes } = useGetPublicRoutes();

	return (
		<ErrorBoundary FallbackComponent={ErrorPage}>
			<Routes>
				{renderRoutes(publicRoutes)}
				<Route path="*" element={<Navigate to={RoutersFullpathEnum.public_not_found} />} />
			</Routes>
		</ErrorBoundary>
	);
};

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route
			path="*"
			element={
				// @ts-ignore
				<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
					<ConfluenceOauthProvider>
						<AuthProvider>
							<InitializationProvider>
								<>
									<ScrollToTop />
									<MessageDisplayer />
									<OnboardingProvider>
										<BreadcrumbsProvider>
											<Layout>
												<Root />
											</Layout>
										</BreadcrumbsProvider>
									</OnboardingProvider>
								</>
							</InitializationProvider>
						</AuthProvider>
					</ConfluenceOauthProvider>
				</GoogleOAuthProvider>
			}
		/>,
	),
);

const publicRouter = createBrowserRouter(
	createRoutesFromElements(
		<Route
			path="*"
			element={
				// have to wrap PublicRoot in AuthProvider and InitializationProvider to not duplicate components
				// with auth and initialization logic, to use same components for public pages and for private
				<AuthProvider>
					<InitializationProvider>
						<PublicRoot />
					</InitializationProvider>
				</AuthProvider>
			}
		/>,
	),
);

const App = (): JSX.Element => {
	const { pathname } = window.location;

	const routerToRender = pathname.includes(RoutersEnum.public) ? publicRouter : router;

	return <RouterProvider router={routerToRender} />;
};

export default App;
